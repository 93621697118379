<div class="column">
  <section id="upcomingSessionsFaculty" class="bg-grey"
    [ngClass]="sessions != undefined && sessions.length > 0 ? 'upcoming-list' : ''">
    <div class="container">
      <div class="row" *ngIf="!isDisplayed">
        <div class="col text-center">
          <h2>Loading...</h2>
        </div>
      </div>
      <div *ngIf="isDisplayed">
        <div *ngIf="sessions != undefined && sessions.length > 0">
          <div class="row">
            <div class="col m-text-center">
              <button type="button" class="btn mt-2 btn-secondary" [routerLink]="['/set-availability']"><span
                  class="ico">+</span><u class="no-text-decoration">Set Availability</u></button>
            </div>
          </div>
          <div class="row" *ngFor="let sess of sessions">
            <div class="col">
              <div class="card-session">
                <div class="row mobile-sessions">
                  <div class="col">
                    <h2><b>Date:</b> {{ sess.date }} at {{ sess.time }} {{ sess.zoneAbbr }}</h2>
                    <h2><b>Topic:</b> {{ sess.topic }}</h2>
                    <h2><b>Participant:</b> {{ sess.participantFirstName }} {{ sess.participantLastName }}</h2>
                  </div>
                  <div class="col-5 btn-container">
                    <div class="button">
                      <button type="button" class="btn-primary" (click)="joinSession(sess.id, sess.ts)">
                        Start Session
                      </button>
                    </div>
                    <div class="button-text">
                      <button type="button" class="btn-primary" (click)="showDeleteModal(sess.id)">
                        Send Cancellation Note
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isDisplayNot && (sessions==undefined || sessions.length < 1)">
          <div class="col no-session-text">
            No Upcoming Sessions
          </div>
          <div class="row upcoming-btn">
            <div class="col text-center">
              <a type="button" class="btn btn-primary" [routerLink]="['/set-availability']">Set
                Availability</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Terms Modal -->
    <div id="confirm-popup" *ngIf="deleteModalVisible">
      <div class="confirm-body">
        <div>
          <div>
            <div class="col text-center">
              <p>Are you sure you want to cancel your appointment?</p>
            </div>
          </div>
        </div>

        <div class="confirm-tray">
          <div class="row">
            <div class="col width-30 text-right m-width-100 m-text-center">
              <button type="button" class="btn btn-primary outline-btn bg-light" (click)="deleteModalVisible = false">No</button>
            </div>
            <div class="col m-text-center">
              <button type="button" class="btn btn-primary" (click)="deleteSession()">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
