<div class="column">
    <section id="contentLibrary">
        <div>
            <div class="row">
                <div class="col text-center">
                    <h1 class="purple">Content Library</h1>
                </div>
                <p class="sub-head">
                  The following resources are designed for people living with schizophrenia or for those caring for them.
                  They are designed to support mental health recovery discussions, and may help with conversations on
                  setting goals, identifying appropriate treatment options, life skills, support, and more.
                </p>
            </div>
            <div class="content-headers">
              <div class="content-header" (click)="setGoalSettings()" [ngClass]="this.isGoalSettings ? 'active-header' : '' ">
                Goal<br />Settings
              </div>
              <div class="content-header" (click)="setTreatment()" [ngClass]="this.isTreatmentDiscussions ? 'active-header' : '' ">
                Treatment<br/>
                Discussions
              </div>
              <div class="content-header" (click)="setStayingOnTrack()" [ngClass]="this.isStayingOnTrack ? 'active-header' : '' ">
                Staying on<br/>
                Track
              </div>
              <div class="content-header" (click)="setSupportNetwork()" [ngClass]="this.isSupportNetwork ? 'active-header' : '' ">
                Support<br />Network
              </div>
            </div>
            <div class="goal-settings content-div" *ngIf="this.isGoalSettings">
                <div class="content-container" >
                    <span class="title purple desktop-hide">
                      Goal Setting
                    </span>
                    <div class="content-image">
                      <img src="assets/images/Resources/GoalSetting.png">
                    </div>
                    <div class="content-detail">
                      <p class="title purple mobile-hide">
                        Goal Setting
                      </p>
                      <p class="content-desc">
                        A worksheet to help you set new recovery goals that are
                        meaningful and important to YOU.
                      </p>
                      <a href="assets/lib/Goal_Setting/cp-110938v3_goal_setting.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
                    </div>
                </div>
                <div class="content-container" >
                    <span class="title purple desktop-hide">
                      Choosing Your Goals
                    </span>
                    <div class="content-image">
                      <img src="assets/images/Resources/ChoosingYourGoals.png">
                    </div>
                    <div class="content-detail">
                      <p class="title purple mobile-hide">
                        Choosing Your Goals
                      </p>
                      <p class="content-desc">
                        A worksheet to help you identify how satisfied or happy you
                        are in different areas of your life. It will also help you decide
                        in which areas you want to make changes and set new goals.
                      </p>
                      <a href="assets/lib/Goal_Setting/cp-110936v3_choosing_goals.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
                    </div>
                </div>
                <div class="content-container" >
                    <span class="title purple desktop-hide">
                        Exploring Your Beliefs
                    </span>
                    <div class="content-image">
                      <img src="assets/images/Resources/ExploringYourBeliefs.png">
                    </div>
                    <div class="content-detail">
                      <p class="title purple mobile-hide">
                        Exploring Your Beliefs
                      </p>
                      <p class="content-desc">
                        A worksheet that can help you become aware of your beliefs
                        and understand them.
                      </p>
                      <a href="assets/lib/Goal_Setting/cp-110941v4_exploring_your_med_beliefs.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
                    </div>
                  </div>
                  <div class="content-container" >
                      <span class="title purple desktop-hide">
                        Taking Steps Towards Your Recovery Goals
                      </span>
                      <div class="content-image">
                        <img src="assets/images/Resources/TakingStepsTowardsYourRecoveryGoals.png">
                      </div>
                      <div class="content-detail">
                        <p class="title purple mobile-hide">
                          Taking Steps Towards Your Recovery Goals
                        </p>
                        <p class="content-desc">
                          A worksheet to help you identify how satisfied or happy you
                          are in different areas of your life. It will also help you decide
                          in which areas you want to make changes and set new goals.
                        </p>
                        <a href="assets/lib/Goal_Setting/cp-54209v6_recovery_goals.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
                      </div>
                  </div>
                  <div class="content-container" >
                      <span class="title purple desktop-hide">
                        Staying on Track
                      </span>
                      <div class="content-image">
                        <img src="assets/images/Resources/ChoosingYourGoals.png">
                      </div>
                      <div class="content-detail">
                        <p class="title purple mobile-hide">
                          Staying on Track
                        </p>
                        <p class="content-desc">
                          A worksheet to help you prepare for changes in care and stay
                          on course with your treatment plan.
                        </p>
                        <a href="assets/lib/Goal_Setting/cp-53201v7_staying_on_track.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
                      </div>
                  </div>
            </div>
            <div class="treatment-discussions content-div" *ngIf="this.isTreatmentDiscussions">
              <div class="content-container" >
                <span class="title purple desktop-hide">
                  Understanding Mental Health Episodes
                </span>
                <div class="content-image">
                  <img src="assets/images/Resources/UnderstandingMentalHealthEpisodes%20.png">
                </div>
                <div class="content-detail">
                  <p class="title purple mobile-hide">
                    Understanding Mental Health Episodes
                  </p>
                  <p class="content-desc">
                    This worksheet can help you take control of your
                    recovery journey.
                  </p>
                  <a href="assets/lib/Treatment_Discussions/cp-110950v3_understanding_episodes.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
                </div>
              </div>
              <div class="content-container" >
                <span class="title purple desktop-hide">
                  Treatment and Recovery Log
                </span>
                <div class="content-image">
                  <img src="assets/images/Resources/TreatmentandRecoveryLog.png">
                </div>
                <div class="content-detail">
                  <p class="title purple mobile-hide">
                    Treatment and Recovery Log
                  </p>
                  <p class="content-desc">
                    A journal for writing down your treatment and medication
                    preferences and recovery history.
                  </p>
                  <a href="assets/lib/Treatment_Discussions/cp-25171v7_treatment_and_recovery_journal.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
                </div>
              </div>
              <div class="content-container" >
                <span class="title purple desktop-hide">
                    Medication Thoughts and Strategies
                </span>
                <div class="content-image">
                  <img src="assets/images/Resources/MedicationThoughtsandStrategies%20.png">
                </div>
                <div class="content-detail">
                  <p class="title purple mobile-hide">
                    Medication Thoughts and Strategies
                  </p>
                  <p class="content-desc">
                    A worksheet to help you talk with your doctor or other
                    treatment team members about the way you think about
                    medication.
                  </p>
                  <a href="assets/lib/Treatment_Discussions/cp-110952v3_medication_thoughts.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
                </div>
              </div>
              <div class="content-container" >
                <span class="title purple desktop-hide">
                  Preparing for an HCP Visit
                </span>
                <div class="content-image">
                  <img src="assets/images/Resources/PreparingforanHCPVisit.png">
                </div>
                <div class="content-detail">
                  <p class="title purple mobile-hide">
                    Preparing for an HCP Visit
                  </p>
                  <p class="content-desc">
                    A worksheet to help you and members of your treatment
                    team prepare for your next doctor appointment.
                  </p>
                  <a href="assets/lib/Treatment_Discussions/cp-110946v3_prep_for_hcp_visit.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
                </div>
              </div>
              <div class="content-container" >
                <span class="title purple desktop-hide">
                  Making Medication Decisions
                </span>
                <div class="content-image">
                  <img src="assets/images/Resources/MakingMedicationDecisions.png">
                </div>
                <div class="content-detail">
                  <p class="title purple mobile-hide">
                    Making Medication Decisions
                  </p>
                  <p class="content-desc">
                    A worksheet to help you begin conversations with your
                    treatment team.
                  </p>
                  <a href="assets/lib/Treatment_Discussions/cp-110940v4_making_med_decisions.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
                </div>
              </div>
              <div class="content-container" >
                <span class="title purple desktop-hide">
                  Empowered to Participate
                </span>
                <div class="content-image">
                  <img src="assets/images/Resources/EmpoweredtoParticipate.png">
                </div>
                <div class="content-detail">
                  <p class="title purple mobile-hide">
                    Empowered to Participate
                  </p>
                  <p class="content-desc">
                    A worksheet to empower you to partner with your doctor
                    around medication and treatment decisions.
                  </p>
                  <a href="assets/lib/Treatment_Discussions/cp-52704v4_med_decisions_empowerment.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
                </div>
              </div>
            </div>
          <div class="treatment-discussions content-div" *ngIf="this.isStayingOnTrack">
            <div class="content-container" >
                <span class="title purple desktop-hide">
                  Making Choices in Your Recovery Journey
                </span>
              <div class="content-image">
                <img src="assets/images/Resources/MakingChoicesin%20YourRecoveryJourney.png">
              </div>
              <div class="content-detail">
                <p class="title purple mobile-hide">
                  Making Choices in Your Recovery Journey
                </p>
                <p class="content-desc">
                  Making choices and decisions along the mental health
                  recovery journey is part of the process.
                </p>
                <a href="assets/lib/Staying_on_Track/cp-54300v7_making_choices.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
              </div>
            </div>
            <div class="content-container" >
                <span class="title purple desktop-hide">
                  Living With Your Diagnosis
                </span>
              <div class="content-image">
                <img src="assets/images/Resources/LivingWithYourDiagnosis.png">
              </div>
              <div class="content-detail">
                <p class="title purple mobile-hide">
                  Living With Your Diagnosis
                </p>
                <p class="content-desc">
                  This worksheet can help you with your efforts to find solutions
                  to potential challenges you might face as you continue school
                  after your diagnosis.
                </p>
                <a href="assets/lib/Staying_on_Track/cp-331682v2_living_with_dx_educational_assets.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
              </div>
            </div>
            <div class="content-container" >
                <span class="title purple desktop-hide">
                    Methods for Successful Employment
                </span>
              <div class="content-image">
                <img src="assets/images/Resources/MethodsforSuccessfulEmployment%20.png">
              </div>
              <div class="content-detail">
                <p class="title purple mobile-hide">
                  Methods for Successful Employment
                </p>
                <p class="content-desc">
                  This worksheet can help you with your efforts to find solutions
                  to potential challenges you might face as you continue to
                  work or look for employment after your diagnosis.
                </p>
                <a href="assets/lib/Staying_on_Track/cp-331683v3_employment_strategies.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
              </div>
            </div>
            <div class="content-container" >
                <span class="title purple desktop-hide">
                  Problem Solving in Your Recovery Journey
                </span>
              <div class="content-image">
                <img src="assets/images/Resources/PreparingforanHCPVisit.png">
              </div>
              <div class="content-detail">
                <p class="title purple mobile-hide">
                  Problem Solving in Your Recovery Journey
                </p>
                <p class="content-desc">
                  Finding the appropriate treatment options for you is an
                  important part of your recovery process
                </p>
                <a href="assets/lib/Staying_on_Track/cp-55401v7_problem_solving.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
              </div>
            </div>
          </div>
          <div class="treatment-discussions content-div" *ngIf="this.isSupportNetwork">
            <div class="content-container" >
                <span class="title purple desktop-hide">
                  Making Decisions Together
                </span>
              <div class="content-image">
                <img src="assets/images/Resources/MakingDecisionsTogether.png">
              </div>
              <div class="content-detail">
                <p class="title purple mobile-hide">
                  Making Decisions Together
                </p>
                <p class="content-desc">
                  Mental health recovery is a lifelong journey. There are
                  ups and downs and twists and turns along the way, but
                  it’s important to be hopeful.
                </p>
                <a href="assets/lib/Support_Network/cp-63642v7_making_decisions.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
              </div>
            </div>
            <div class="content-container" >
                <span class="title purple desktop-hide">
                  Partners in Care
                </span>
              <div class="content-image">
                <img src="assets/images/Resources/PartnersinCare.png">
              </div>
              <div class="content-detail">
                <p class="title purple mobile-hide">
                  Partners in Care
                </p>
                <p class="content-desc">
                  A worksheet that helps you involve loved ones in your
                  recovery journey in ways that feel comfortable to each of you.
                </p>
                <a href="assets/lib/Support_Network/cp-53757v7_partners_in_care.pdf?id={{userId}}" class="btn btn-primary bg-purple mt-3" target="_blank">Download Now</a>
              </div>
            </div>
          </div>
        </div>
    </section>
</div>
