import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { format } from 'date-fns';
import { date } from 'ngx-custom-validators/src/app/date/validator';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';

import { AvailabilitiesService } from '@api/availabilities.service';
import { Availability } from '@models/captain';
import { StateService } from '@api/state.service';
import { AppointmentsService } from '@api/appointments.service';
import { AppointmentData } from '@models/appointment';
import { AuthService } from '@api/auth.service';
import { utcToZonedTime } from 'date-fns-tz';
import { CalendarEvent, CalendarView } from 'angular-calendar';



const currYr = new Date().getFullYear();


@Component({
  selector: 'oamw-select-session',
  templateUrl: './select-session.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./select-session.component.scss']
})
export class SelectSessionPage implements OnInit {
  view: string = 'month';

  refresh: Subject<any> = new Subject();

  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  showAll = false;
  isLoggedIn = true;

  colors: any = {
    green: {
      primary: '#a8d29f',
      secondary: '#a8d29f',
    },
    gray: {
      primary: '#bbbbbb',
      secondary: '#bbbbbb',
    }
  };

  topicId = this.state.selectedTopicByParticipant;
  captainId = this.state.selectedCaptainByParticipant;
  participantId = this.state.user.id;

  selectedDate: String;
  selectedMonth: string;
  selectedYear: string;
  selectedSession: string;
  errorMessage = '';
  isProcessing = false;
  selectModalVisible = false;

  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
            'August', 'September', 'October', 'November', 'December'];
  years = [0].map(n => currYr + n);
  availableDates: { date: string, times: { id: string, time: string }[] }[] = [];

  availabilities: Availability[] = [];

  constructor(private router: Router,
              private availabilitiesService: AvailabilitiesService,
              private state: StateService,
              private appointmentService: AppointmentsService,
              private authService: AuthService,
              private location: Location,
              private auth: AuthService) { }

  ngOnInit(): void {
    !this.state.hasToken() && this.router.navigate(['/']);
    this.auth.isLoggedIn.subscribe( status => this.isLoggedIn = status );
    this.getAvailabilities(this.state.selectedCaptainByParticipant);
  }

  async getAvailabilities(captainId: string) {
    let available = await this.availabilitiesService.getAvailabilities(captainId).toPromise();
      

    this.availabilitiesService
      .getAvailabilitieByDate(captainId,
        new Date().toISOString(),
        null)
      .subscribe(availabilities => {
        this.availabilities = availabilities.map( d => {
          var tempData = {};
          let t = utcToZonedTime(d.startDate, this.state.user.tzCanonicalName);
          let zonedConverted = new Date(t.getFullYear(), t.getMonth(), t.getDate(), t.getHours(), t.getMinutes());
          d.startDate = zonedConverted.toISOString();
          var endDate = new Date(d.startDate);
          endDate.setMinutes(endDate.getMinutes() + 30);
          const startTime = format(new Date(d.startDate), 'hh:mm a');
          const endTime = format(endDate, 'hh:mm a');
          var title = startTime + "-" + endTime;
          const isAvail = available.findIndex(a => a.id === d.id) >= 0 ;
          const meta = {isAvailable: isAvail};

          this.events.push({start: new Date(d.startDate), title: title, end: endDate, id: d.id, color: isAvail ? this.colors.green : this.colors.gray, 
            meta, cssClass: isAvail ? '' : 'booked-slot'});

          d.isAvailable = isAvail;
          return d;
        });
        this.refreshView();
        let dt = new Date();
        this.selectModalVisible = false;

      });

    
  }

  toggleDropdown(event: MouseEvent) {
    const elem = event.target as HTMLElement;
    const selectElem = elem.closest('div.ke-select');

    selectElem.classList.toggle('open');
  }

  toggleCollapse(event: MouseEvent) {
    const elem = event.target as HTMLElement;
    const header = elem.closest('div.available-day');
    const content = header.nextElementSibling;

    header.classList.toggle('collapsed');
    content.classList.toggle('show');
  }

  gotoBooked(availabilityId: string) {

    if (this.isProcessing) { return; }

    this.isProcessing = true;
    this.errorMessage = '';
    const postData: AppointmentData = {
      topicId: this.topicId,
      captainId: this.captainId,
      participantId: this.participantId,
      availabilityId
    };

    this.appointmentService
        .addAppointment(postData)
        .subscribe(this.handleResponse.bind(this));
  }

  ///

  private calculateAvailableDates(event: CalendarEvent) {
    const today = new Date();

    let availDays = this.availabilities.filter(a => a.isAvailable).map( a => {
      return format(new Date(a.startDate), 'EEEE, MMM dd, yyyy');
    });
    var selectedDate: Date = event.start;
    availDays = availDays.filter((item, pos) => {
      const dt = new Date(item);
      return dt.getFullYear() === selectedDate.getFullYear()
      &&  selectedDate.getMonth() === dt.getMonth()
      &&  selectedDate.getDate() === dt.getDate()
      &&  availDays.indexOf(item) === pos;
    });

    availDays.forEach(day =>
      this.availabilities.filter(a => a.isAvailable).forEach(hr => {
        if (format(new Date(hr.startDate), 'EEEE, MMM dd, yyyy') !== day) return;
        const availDt = new Date(hr.startDate);
        const today1 = new Date();
        const dt = day;
        // const time = format(availDt, 'hh:mm a');
        
        var endDate = new Date(hr.startDate);
        endDate.setMinutes(endDate.getMinutes() + 30);
        const startTime = format(new Date(hr.startDate), 'hh:mm a');
        const endTime = format(endDate, 'hh:mm a');
        var time = startTime + " - " + endTime;

        let dtIdx = this.availableDates.findIndex(d => d.date === dt);

        if (dtIdx < 0) {
          this.availableDates.push({ date: dt, times: [] });
          dtIdx = this.availableDates.length - 1;
        }

        this.availableDates[dtIdx].times.push({ id: hr.id, time });
      })
    );
  }

  handleResponse(success) {
    this.isProcessing = false;
    console.log(success);
    if (success instanceof Error) {
      this.errorMessage = success.message.replace('Captain', 'Ambassador');
    }
    else if (success){
      this.authService.getUserDetails();
      success && this.router.navigate(['/booked']);
    }
  }

  handleEvent(action: string, event: CalendarEvent): void {
    console.log('Event clicked', event);
    this.calculateAvailableDates(event);
    var selectedDate: Date = event.start;
    this.selectedDate = selectedDate.getDate().toString();
    this.selectedYear = selectedDate.getFullYear().toString();
    this.selectedMonth = selectedDate.toLocaleString('default', { month: 'long' });
    this.showSelectModal();
  }

  ///

  showSelectModal() {
    this.selectModalVisible = true;
  }



  closeSelectModal() {
    this.selectModalVisible = false;
    this.availableDates = [];
  }

  refreshView(): void {
    this.refresh.next();
  }

  goBack() {
    this.location.back();
  }
}
