<div class="column" [ngClass]="isLoggedIn ? '' : 'right-signup'">
  <section id="booked" class="bg-grey">
    <div>
      <div>
        <div class="text-center">
          <h1 class="purple">Your session has<br />been booked!</h1>
        </div>
        <div class="col text-center desktop-hide">
          <img src="assets/images/process-step-four-m.png" class="img-fluid step-image-padding">
        </div>
        <div class="col text-center mobile-hide">
          <img src="assets/images/process-step-four.png" class="img-progress">
        </div>
        <div class="col text-center">
          <h2 class="booked-text purple">Press continue to view your<br />personalized dashboard</h2>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button type="button" class="btn btn-primary continue-btn" (click)="gotoUpcoming()">Continue</button>
        </div>
      </div>
    </div>
  </section>
  <div (click)="goBack()" class="pointer left-arrow" *ngIf="isLoggedIn">
    <img src="assets/images/backBTN.png" class="back-arrow">
  </div>
</div>
